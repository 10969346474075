.load-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.img{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 250px;
}